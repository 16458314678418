import { navigate } from 'gatsby'
import React, { useContext, useEffect } from 'react'
import { connect } from 'react-redux'
import SelectorContainer from '../../../src/common/components/FunnelSelector/Entrada/SelectorContainer'
import SelectorForm from '../../../src/common/components/FunnelSelector/Entrada/SelectorForm'
import Step, {
  findNext,
  InSkipInfo,
} from '../../../src/common/components/FunnelSelector/SelectorSteps'
import SearchNeumaticos from '../../../src/common/components/searchNeumaticosCardHome/index'
import ResponsiveSliderBanner from '../../../src/common/components/ResponsiveSliderBanner/ResponsiveSliderBanner'
import Title from '../../../src/common/components/Title'
import { reset } from '../../../src/common/context/actions/selectorActions'
import AppContext, { IAppState } from '../../../src/common/context/context'
import { selectSkipInfo } from '../../../src/common/context/selectors/selectorSelectors'
import { temporadaMapping } from '../../../src/specific/helpers/gtmReporter'
import route from '../../../src/common/utils/route'
import { getFullCompany } from '../../../src/common/utils/getCompany'
import { locale } from '../../../src/common/i18n'
import { getImages, ImageInputData } from '../../../src/common/utils/imagesS3'
import { SeoData } from '../../../src/common/types/Seo'

const placeholderImage = {
  Key: '',
  url: 'https://via.placeholder.com/800x200?text=Placeholder+Image',
  link: '',
  alt: '',
}
interface InSeoData {
  seoData: SeoData
}
interface Props {
  styles: { [k: string]: string }
  data?: InSeoData | ImageInputData
  tablet?: boolean
  desktop?: boolean
}

const LandingQuality = ({ styles, data }: Props) => {
  const banners_mobile = getImages(
    data[`${'banners'}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const banners_desktop = getImages(
    data[`${'banners'}_desktop`].nodes,
    locale,
    placeholderImage
  )

  return (
    <div className={styles.container}>
      <Title text="Pneus Quality" />
      <Selector styles={styles} />
      <article className={styles.description}>
        <p className={styles.main_description}>
        Na Rodi Motor, trabalhamos com as marcas de pneus com o melhor equilíbrio entre qualidade e preço do mercado, podendo oferecer os melhores preços com uma excelente qualidade de serviço. Estas segundas marcas permitir-lhe-ão poupar na sua compra em relação às marcas premium, mantendo, ao mesmo tempo, excelentes prestações em termos de segurança e fiabilidade.
        </p>
        <ResponsiveSliderBanner
          desktopImages={banners_desktop}
          mobileImages={banners_mobile}
          sizesVariant={'banner_landings'}
        />

        <section className={styles.section}>
          <h2>Grande seleção de Pneus Quality </h2>
          <p>
          As segundas marcas são uma grande escolha para os condutores que precisam de uma mudança de pneus e precisam de poupar no investimento sem perder qualidade nos pneus que compram.
          </p>
        </section>
        <section className={styles.section}>
          <p>
          Na Rodi Motor, temos uma excelente seleção de Marcas Quality que têm pouco ou nada a invejar às marcas premium para além do seu reconhecimento. Algumas destas marcas pertencem a fabricantes de pneus premium e comportam-se como a sua segunda marca. São rodas de excelente qualidade a preços acessíveis que oferecem um desempenho muito bom em praticamente todas as situações.
          </p>
        </section>
        <section className={styles.section}>
          <p>
          Estas marcas partilham materiais e processos de fabrico com as marcas mais importantes reconhecidas pelo público em geral. Portanto, as diferenças notórias para o condutor normal não estão muito longe do desempenho oferecido por um pneu premium.
          </p>
        </section>
        <section className={styles.section}>
          <p>
          Os pneus são um dos elementos mais importantes da segurança do carro. As marcas quality são uma alternativa mais económica que lhe permite desfrutar da condução sem renunciar à segurança e à qualidade a um preço muito mais barato.
          </p>
        </section>
        <section className={styles.section}>
          <p>
          Algumas das nossas marcas quality mais vendidas são a Falken, BFGoodrich, Kleber, CooperTires e
          Firestone.
          </p>
        </section>
      </article>
    </div>
  )
}

export default LandingQuality

interface SelectorProps {
  styles: { [k: string]: string }
  dispatch: (action: any) => void
  skipInfo: InSkipInfo
}

const ConnectedSelector = ({ dispatch, styles, skipInfo }: SelectorProps) => {
  const { selectorState } = useContext(AppContext)
  // Initialize selector values
  useEffect(() => {
    dispatch(
      reset({
        keepValues: false,
        marca: null,
        origen: route('landing-quality'),
        temporada: { selectable: false, value: 'verano' },
        tipoVehiculo: null,
      })
    )
  }, [])

  return (
    <React.Fragment>
      <SearchNeumaticos
        gtmData={{
          action: 'verano',
          runflat: selectorState.runflat.value,
          tipo_neumaticos: temporadaMapping[selectorState.temporada.value],
        }}
      />

      <div className={styles.selector_container}>
        <SelectorContainer showMarcas={false} origin={route('landing-quality')}>
          {({ ...props }) => (
            <SelectorForm
              showTemporada={false}
              fromModal={false}
              handleSubmit={() => {
                const next = findNext(Step.SELECT_MARCAS, 'desktop', skipInfo)
                navigate(next.routes.desktop)
              }}
              {...props}
            />
          )}
        </SelectorContainer>
      </div>
    </React.Fragment>
  )
}
const Selector = connect((state: IAppState) => ({
  skipInfo: selectSkipInfo(state),
}))(ConnectedSelector)
